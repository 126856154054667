var api = "https://api.qingcigame.com";
var obj = {
    index: function() {
        this.preloading();          // 预加载
        // this.indexSwiper();         // 首页大轮播
        this.indexAjax();           // 首页接口请求
        this.turn();                // 角色转身的循环
        this.floatCode();           // 游戏下载浮窗
        this.jqslideTxtBox();       // 游戏咨询信息切换
        this.Textlength(28, 25);    // 内容字体长度超过40，标题字体长度超过25
        this.videoAndImg();         // 视频和图片预览
        this.indexBind();           // 首页按钮
        this.share();               // 分享链接
        this.countNum();            //阿里云埋点
    },
    // 新闻列表
    newsList: function() {
        var cate_id, page, current, index;
        this.floatCode();           // 游戏下载浮窗
        this.newsZxList();          // 新闻最新列表
        this.newsTabPage();         // 最新、综合、活动跳转
    },
    // 新闻详情
    newsDetails: function() {
        this.floatCode();           // 游戏下载浮窗
        this.newsInfo();
        this.scroll();
    },
    // 游戏攻略列表
    intrList: function() {
        var cate_id, page, current, index;
        this.floatCode();           // 游戏下载浮窗
        this.newsIntrList();          // 新闻最新列表
        this.newsTabPage();         // 最新、综合、活动跳转
    },
    // 同人专区
    colleagues: function() {
        this.floatCode();           // 游戏下载浮窗
        this.worksAjax();           // 同人专区-作品
        this.worksTabPage();        // 全部、同人绘、手作
    },
    // 同人专区-作品详情
    drawingDetails: function() {
        this.floatCode();           // 游戏下载浮窗
        this.drawingInfo();         // 获取数据
        this.scroll();
    },
    // 视频中心
    videoCenter: function() {
        this.floatCode();           // 游戏下载浮窗
        this.videoCenterAjax();     // 获取视频中心视频
        this.videoSwitch();         // 选择播放的视频
    },
    // 视频详情
    videoDetails: function() {
        this.floatCode();           // 游戏下载浮窗
        this.videoDetailsAjax();    // 获取详细的视频
    },
    // 同名动画
    sameNameAni: function() {
        this.floatCode();           // 游戏下载浮窗
        this.sameNameAjax();        // 获取同名动画视频
        this.videoSwitch();         // 选择播放的视频
        this.channel();             // 选择不同频道
    },
    // 漫画续集
    comicSequel: function() {
        this.floatCode();           // 游戏下载浮窗
        this.comicSequelAjax();     // 获取漫画续集
    },
    // wiki-贵重物品
    wikiPrecious: function() {
        var rarity, deviation, mosaic_skill, halo_effect, column, order, page, url;
        this.floatCode();           // 游戏下载浮窗
        this.getWikiData();         // 获取筛选的参数
        this.filtering();           // wiki-贵重物品-筛选
        this.wikiearch();           // wiki-贵重物品-搜索
        this.wikiTabInfo();         // tab切换信息
        this.wikiPopup();           // 弹窗的开关查看详情
    },
    // wiki-index
    wikiIndex: function() {
        this.floatCode();           // 游戏下载浮窗
        // this.wikiSwiper();          // wikiSwiper的轮播
        this.wikiIndexAjax();
    },
    // 首页暂未开放的几个按钮
    indexBind: function() {
        $('.monitor-btn').on('click', function() {
            var data_event = $(this).attr('data-event');
            var data_url = $(this).attr('data-url');
            console.log(data_event)
            obj.countNum(data_event);
            if(data_url !== "") {
                setTimeout(() => {
                    location.href = data_url;
                }, 200);
            }
        })

        $('.monitor-btn-2').on('click', function() {
            var data_event = $(this).attr('data-event');
            console.log(data_event)
            obj.countNum(data_event);
        })

        $(".charity-btn").hover(function() { 
            $('.charity-img').addClass('sel-hover');
        }, function() { 
            $('.charity-img').removeClass('sel-hover');
        });

        $('.banner .play-btn').click(function(event) {
            layer.msg("暂未开放，敬请期待!", {time: 2000});
        });

        $('.recommend-popup').on('click', '.close-btn', function(event) {
            $('.rec-box').addClass('hide-ani')
            $('.recommend-popup').fadeOut();
        });
        //适龄弹窗
        $('.year_box').on('click', function() {
            $('.year_pop').fadeIn();
        });
        $('.close-btn').click(function(event) {
            $('.year_pop').fadeOut();
        });
    },
    // iframe数据导入
    newsIframe: function() {
        var id = this.getQueryVariable('id');
        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/article/"+id+"",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    var detailsInfo = json.data.list.article_content;   // 内容
                    $('body .iframe').append(detailsInfo);
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }
            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 图片资源预加载
    preloading: function () {
        //图片预加载
        var queue = new createjs.LoadQueue();
        queue.on("progress", function (e) {
            //加载中的进度
            //加载中的进度
            if (e.progress * 100 >= 10) {
                $(".loding_time span").width(parseInt(e.progress * 100) + '%')
            };
            var numbers = e.progress * 100;

        }, this);
        queue.on("complete", function (e) {
            //加载完成执行操作
            $('.loding_time').remove();
            $('.preloading').addClass('preloading-ani').fadeOut(1000);
            $('.index').addClass('index-show');
            this.indexSwiper();
            // $('.recommend-popup').fadeIn();     // 新增加弹窗
            setTimeout(function() {
                $('.preloading').remove();
            },1000)
        }, this);
        queue.loadManifest([
            //加载图片，不包括后台的
            '//static.qcplay.com/snail/official/images/pc/m_dt_sz.png',
            '//static.qcplay.com/snail/official/images/pc/m_dt_t.png',
            '//static.qcplay.com/snail/official/images/pc/m_jdt.png',
            '//static.qcplay.com/snail/official/images/pc/float_code.png',
            '//static.qcplay.com/snail/official/images/pc/header_bg.png',
            '//static.qcplay.com/snail/official/images/pc/snail_ke.png',
            '//static.qcplay.com/snail/official/images/pc/snil.png',
            '//static.qcplay.com/snail/official/images/pc/index_bg.jpg',
            '//static.qcplay.com/snail/official/images/pc/app_btn.png',
            '//static.qcplay.com/snail/official/images/pc/and_btn.png',
            '//static.qcplay.com/snail/official/images/pc/combination/woniu.png',
            '//static.qcplay.com/snail/official/images/pc/combination/robot_02.png',
            '//static.qcplay.com/snail/official/images/pc/time_machine.png',
            '//static.qcplay.com/snail/official/images/pc/combination/time_machine.png',
            '//static.qcplay.com/snail/official/images/pc/combination/frog_02.png',
            '//static.qcplay.com/snail/official/images/pc/combination/dan_stove.png',
            '//static.qcplay.com/snail/official/images/pc/combination/ripple.png',
            '//static.qcplay.com/snail/official/images/pc/combination/baikedou1.png',
            '//static.qcplay.com/snail/official/images/pc/combination/heikedou2.png',
            '//static.qcplay.com/snail/official/images/pc/combination/heikedou3.png',
            '//static.qcplay.com/snail/official/images/pc/combination/baikedou0.png',
            '//static.qcplay.com/snail/official/images/pc/combination/wn.png'
        ]);
    },
    // 首页接口请求
    indexAjax: function() {
        var index = layer.load(2, {shade: [0.1, '#fff']});
        var _this = this;
        $.ajax({
            url: api +"/web/index",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                var zonghe   = json.data.articleList.zonghe;
                var activity = json.data.articleList.activity;
                var cartoon  = json.data.articleList.cartoon;
                var jinjie   = json.data.articleList.jinjie;
                var mengxin  = json.data.articleList.mengxin;
                var video    = json.data.articleList.video;
                var jijie    = json.data.articleList.jijie;
                if(json.code == 200) {
                    // 综合新闻
                    $.each(zonghe, function(index, values) {
                        var zongheHtml = '<li>'+
                            '<a href="news-details.html?id='+values.id+'">'+
                                '<h2>'+values.article_title+'</h2>'+
                                '<p>'+values.article_excerpt+'</p>'+
                                '<span>'+values.created_at+'</span>'+
                            '</a>'+
                        '</li>';
                        $('.zonghe').append(zongheHtml);
                    });

                    // 活动新闻
                    $.each(activity, function(index, values) {
                        var activityHtml = '<li>'+
                            '<a href="news-details.html?id='+values.id+'">'+
                                '<h2>'+values.article_title+'</h2>'+
                                '<p>'+values.article_excerpt+'</p>'+
                                '<span>'+values.created_at+'</span>'+
                            '</a>'+
                        '</li>';
                        $('.activity').append(activityHtml);
                    });

                    // 萌新入门
                    $.each(mengxin, function(index, values) {
                        var mengxinHtml = '<li>'+
                            '<a href="news-details.html?id='+values.id+'">'+
                                '<h2>'+values.article_title+'</h2>'+
                                '<p>作者：'+values.origin+'</p>'+
                            '</a>'+
                        '</li>';
                        $('.mengxin').append(mengxinHtml);
                    });

                    // 进阶变强
                    $.each(jinjie, function(index, values) {
                        var jinjieHtml = '<li>'+
                            '<a href="news-details.html?id='+values.id+'">'+
                                '<h2>'+values.article_title+'</h2>'+
                                '<p>作者：'+values.origin+'</p>'+
                            '</a>'+
                        '</li>';
                        $('.jinjie').append(jinjieHtml);
                    });

                    // 集结
                    $.each(jijie, function(index, values) {
                        var jijieHtml = '<div class="other-inf">'+
                            '<a href="drawing-details.html?article='+values.id+'"><i></i></a>'+
                            '<div class="shadow"></div>'+
                            '<div class="banner">'+
                                '<img src="'+values.thumbnail+'">'+
                            '</div>'+
                            '<div class="botton-inf">'+
                                '<img src="'+values.video_link+'">'+
                                '<span>'+
                                    '<h4>'+values.origin+'</h4>'+
                                    '<p><b>'+values.article_title+'</b><i>'+values.like_num+'</i></p>'+
                                '</span>'+
                            '</div>'+
                        '</div>';
                        $('.other-wrap').append(jijieHtml);
                    });
                    $('.other-wrap .other-inf:nth-child(1)').addClass('other-01');
                    $('.other-wrap .other-inf:nth-child(2)').addClass('other-02');
                    $('.other-wrap .other-inf:nth-child(3)').addClass('other-03');
                    

                    // 漫画3则  
                    // if(cartoon.length >= 3)  {
                    //     $('.cartoon-ul .li-01').find('img').attr('src', cartoon[0].thumbnail).attr('data', cartoon[0].video_link);
                    //     $('.cartoon-ul .li-02').find('img').attr('src', cartoon[1].thumbnail).attr('data', cartoon[1].video_link);
                    //     $('.cartoon-ul .li-04').find('img').attr('src', cartoon[2].thumbnail).attr('data', cartoon[2].video_link);                        
                    // }    
                    if(video.length !== 0)  {
                        // 视频添加
                        $('.cartoon-ul .li-01').find('img').attr('src',video[0].thumbnail).attr('data', video[0].video_link); 
                        $('.cartoon-ul .li-02').find('img').attr('src',video[1].thumbnail).attr('data', video[1].video_link); 
                        $('.cartoon-ul .li-03').find('img').attr('src',video[2].thumbnail).attr('data', video[2].video_link); 
                        $('.cartoon-ul .li-04').find('img').attr('src',video[3].thumbnail).attr('data', video[3].video_link);                       
                    }
                    _this.Textlength(28, 25);
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })

        $.ajax({
            url: api +"/web/news/info",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                var article = json.data.article;
                if(json.code == 200) {
                    // 游戏资讯页-左侧
                    $('.slideTxtBox-03 .bd-bg .hd ul li, .slideTxtBox-03 .bd-bg .bd ul li, .hot-activities .box').remove();
                    $.each(article.game_information, function(index, values) {
                        var gameInfoIndex = '<li></li>';
                        $('.slideTxtBox-03 .bd-bg .hd ul').append(gameInfoIndex);
                    });
                    $.each(article.game_information, function(index, values) {
                        var gameInfo = '<li>'+
                            '<div class="img-box">'+
                                '<img class="changeImg" src="'+values.pc_image+'">'+
                            '</div>'+
                            '<span><a href="'+values.jump_url+'" target="_blank"><i></i></a></span>'+
                        '</li>';
                        $('.slideTxtBox-03 .bd-bg .bd ul').append(gameInfo);
                    });
                    jQuery(".slideTxtBox-03 .bd-bg").slide({mainCell:".bd ul",autoPlay:true, delayTime:1000});

                    // 同人专区页---公益活动
                    $.each(article.recommend, function(index, values) {
                        var artRecommend = '<div class="box"><div><img src="'+values.pc_image+'"></div>'+
                            '<a href="'+values.jump_url+'" target="_blank"><i></i></a><span></span><div>';
                        $('.hot-activities').append(artRecommend);
                    });
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 全屏滚动判断
    scroll: function() {
        $(window).scroll(function() {
            var scrollTop = $(this).scrollTop();
            var scrollHeight = $(document).height();
            var windowHeight = $(this).height();
            $(document).scrollTop() > 400 ? $('.return-top').fadeIn() : $('.return-top').fadeOut();
        });

        $('.return-top').click(function(event) {
            $(document).scrollTop(0);
        });
    },
    // 首页轮播
    indexSwiper: function() {
        var mySwiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            direction: 'vertical',
            slidesPerView : 'auto',
            paginationClickable: true,
            spaceBetween: 0,
            mousewheelControl: true,
            resistanceRatio : 0,
            onInit: function(swiper){
                swiperAnimate(swiper);
                function Ani (btn, page) {
                    $(btn).on('click', function(){
                        mySwiper.slideTo(page, 1000, true);
                    });
                };
                function navLink() {
                    for (var i = 4; i >= 0; i--) {
                        Ani('.nav-link-0'+i+' .link-a', i);
                    };
                } navLink();
            }, 
            onSlideChangeEnd: function(swiper){ 
                swiperAnimate(swiper);
                $('.nav-link').removeClass('on')
                $('.nav-link-0'+swiper.activeIndex+'').addClass('on');
            },
        });
    },
    // 角色转身的循环
    turn: function() {
        function turn(role, turn){
            $(role).hasClass(turn) ? $(role).removeClass(turn) : $(role).addClass(turn);
        }
        function turnTime(classA, classB, time){
            setInterval(function(){
                turn(classA, classB);
            },time);                
        }
        // 每个动画物体
        var aniRole01 = turnTime('.role-img .woniu span', 'turn', 10000);
        var anikd1 = turnTime('.tadpole-01 span', 'turn', 5000);
        var anikd2 = turnTime('.tadpole-02 span', 'turn', 7500);
        var anikd3 = turnTime('.tadpole-03 span', 'turn', 10000);
        var anikd4 = turnTime('.tadpole-04 span', 'turn', 8000);
        var anikd5 = turnTime('.tadpole-05 span', 'turn', 9000);
        var anikd6 = turnTime('.tadpole-06 span', 'turn', 6000);
        var anikd7 = turnTime('.tadpole-07 span', 'turn', 6000);
        var anikd8 = turnTime('.tadpole-08 span', 'turn', 6000);
        var anikd9 = turnTime('.tadpole-09 span', 'turn', 8000);
        var anikd10= turnTime('.tadpole-010 span', 'turn', 8000);

        // 所有蝌蚪开始运动
        function tadpoleAni(kd, dh) {
            $(kd).addClass(dh);
        }
        for (var i = 10; i >= 0; i--) {
            tadpoleAni('.tadpole-0'+i+'', 'tadpoleAni0'+i+'');
        };
    },
    // 游戏下载浮窗
    floatCode: function() {

        // 下载链接
        $('.btn-box').on('click','a',function() {
            var className = $(this).attr('class');
            // console.log(className)
            var game_id = 39;
            $.ajax({
                type : "get",
                url: 'https://mapi.qingcigame.com/get_url?game_id='+game_id+'',
                dataType: 'json',
                success: function(json) {
                    if (className.indexOf('app-btn') !== -1) {
                        // ios
                        if (json.data.ios_down_url == '') {
                            layer.msg('ios下载暂未开放，敬请期待！<br/>详情请关注官方qq群：666341871', {time:3000});
                        } else {
                            location.href = json.data.ios_down_url
                        }
                    } else {
                        // 安卓
                        if (json.data.android_download_url == '') {
                            layer.msg('安卓下载暂未开放，敬请期待！<br/>详情请关注官方qq群：666341871', {time:3000});
                        } else {
                            location.href = json.data.android_down_url
                        }
                    }
                }
            })
        });


        // 点击左侧二维码浮窗
        $('.float-code .changes-btn').on('click', 'a', function(event) {
            !$('.float-code').hasClass('select') ? $('.float-code').addClass('select') : $('.float-code').removeClass('select');
        });
        $('.float-code').addClass('select');

        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/configInfo",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    // QQ:
                    $.each(json.data.list.QQ, function(index, values) {
                        var mengxinHtml = '<li><p>'+values+'</p></li>';
                        $('.right-QQ ul').append(mengxinHtml);
                    });
                    // 公众号
                    $('.hidden-code-box .code-gzh img').attr('src', json.data.list.official_account_image);
                    // 微博
                    $('.hidden-code-box .code-gfwb img').attr('src', json.data.list.micro_blog);
                    // b博
                    $('.hidden-code-box .code-gfbb img').attr('src', json.data.list.b_blog);
                    // 逗音
                    $('.hidden-code-box .code-gfdy img').attr('src', json.data.list.tiktok);
                    // 客服
                    $('.hidden-service-box .code-img img').attr('src', json.data.list.customer_service_image);
                    // 公众号：
                    $('.hidden-service-box .gzh').html(json.data.list.official_account);
                    // 邮箱
                    $('.hidden-service-box .email').html(json.data.list.email);
                    // 下载二维码
                    $('.code-btn .code-img img').attr('src', json.data.list.down_image_url);
                    $('.float-code .code-img img').attr('src', json.data.list.down_image_url);
                    layer.close(index);
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                    layer.close(index);
                }
            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
                layer.close(index);
            }
        })
        
        $('.nav-link-off-02').click(function(event) {
            layer.msg("暂未开放，敬请期待!", {time: 2000});
        });
    },
    // 游戏咨询信息切换
    jqslideTxtBox: function() {
        jQuery(".slideTxtBox").slide({effect:"leftLoop",trigger:"click",easing:"easeOutElastic"});
        jQuery(".slideTxtBox-02").slide({effect:"top",trigger:"click",easing:"easeOutElastic"});
    },
    // 内容字体长度超过40，标题字体长度超过25
    Textlength: function(x1, x2) {

        $('.slideTxtBox .bd p').each(function(){
            if($(this).text().length>x1){
                var str = $(this).text();
                $(this).text(str.substr(0,x1)+'...');
            }
        });
        $('.slideTxtBox .bd h2').each(function(){
            if($(this).text().length>x2){
                var str = $(this).text();
                $(this).text(str.substr(0,x2)+'...');
            }
        });
    },
    // 获取当前hash的具体参数
    getQueryVariable: function(variable) {
        var query = window.location.href.split('?')[1];
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
            }
        return(false);
    },
    // 视频和图片预览
    videoAndImg: function() {
        var Media = document.getElementById('media-play');
        // 关闭视频
        $('.popup-bg').on('click', '.close-btn', function(event) {
            $('.popup, .popup-video, .popup-img').fadeOut();
            Media.pause();
        });

        // 点击视频浏览
        $('.cartoon-ul').on('click', '.li-sp', function(event) {
            var _this = $(this);
            $('.popup, .popup-video').fadeIn().find('video').attr('src',_this.find('img').attr('data'));
            Media.play();
        });

        $('.cartoon-ul').on('click', '.li-03', function(event) {
            $('.popup, .popup-video').fadeIn();
            Media.play();
        });
    },
    // 新闻最新接口
    newsZxList: function() {
        cate_id = this.getQueryVariable('cate_id');
        page    = this.getQueryVariable('page');
        current = window.location.href.split("?")[0];
        index = layer.load(2, {shade: [0.1, '#fff']});
        $('.news .tab-title .a-tab-0'+cate_id+'').addClass('on').siblings('a').removeClass('on');
        $.ajax({
            url: api +"/web/article",
            type: "GET",
            data: {
                // cate_id 最新-综合-活动
                // page    1...
                cate_id: cate_id,
                page: page,
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    var current_page = json.data.articleList.current_page;  // 当前页数
                    var last_page = json.data.articleList.last_page;        // 总共页数
                    var prev_page_url = json.data.articleList.prev_page_url;// 前一页
                    var next_page_url = json.data.articleList.next_page_url;// 后一页

                    // 渲染列表
                    $.each(json.data.articleList.data, function(index, values) {
                        var dtHtml = '<li>'+
                            '<a title="'+values.article_title+'" href="news-details.html?id='+values.id+'">'+
                                '<i></i><em></em>'+
                                '<p class="lside">'+
                                    '<span class="time">'+values.created_at+'</span>'+
                                    '<span class="title">'+values.article_title+'</span>'+
                                    '<span class="comment">'+values.article_excerpt+'</span>'+
                                '</p>'+
                            '</a>'+
                        '</li>';
                        $('.new-list ul').append(dtHtml);
                    });
                    $('.current_page').html(current_page);      // 当前页数
                    $('.last_page').html(last_page);            // 总共页数
                    if(prev_page_url === null) {                // 上一页
                        $('.front-link').hide(); // 没有第一页
                    } else {
                        $('.front-link').attr('href', current+'?cate_id='+cate_id+'&page='+prev_page_url.split("?page=")[1])
                    }
                    if(next_page_url === null) {                // 下一页
                        $('.after-link').hide(); // 没有最后一页
                    } else {
                        $('.after-link').attr('href', current+'?cate_id='+cate_id+'&page='+next_page_url.split("?page=")[1])        
                    }
                    $('.new-list').addClass('new-list-show');
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 游戏攻略接口
    newsIntrList: function() {
        cate_id = this.getQueryVariable('cate_id');
        page    = this.getQueryVariable('page');
        current = window.location.href.split("?")[0];
        index = layer.load(2, {shade: [0.1, '#fff']});
        $('.news .tab-title .a-tab-0'+cate_id+'').addClass('on').siblings('a').removeClass('on');
        $.ajax({
            url: api +"/web/article",
            type: "GET",
            data: {
                // cate_id 最新-综合-活动
                // page    1...
                cate_id: cate_id,
                page: page,
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    var current_page = json.data.articleList.current_page;  // 当前页数
                    var last_page = json.data.articleList.last_page;        // 总共页数
                    var prev_page_url = json.data.articleList.prev_page_url;// 前一页
                    var next_page_url = json.data.articleList.next_page_url;// 后一页

                    // 渲染列表
                    $.each(json.data.articleList.data, function(index, values) {
                        var dtHtml = '<li>'+
                            '<a title="'+values.article_title+'" href="wiki-details.html?id='+values.id+'">'+
                                '<i></i><em></em>'+
                                '<p class="lside">'+
                                    '<span class="time">'+values.created_at+'</span>'+
                                    '<span class="title">'+values.article_title+'</span>'+
                                    '<span class="comment">'+values.article_excerpt+'</span>'+
                                '</p>'+
                            '</a>'+
                        '</li>';
                        $('.new-list ul').append(dtHtml);
                    });
                    $('.new-list').addClass('new-list-show');
                    $('.current_page').html(current_page);      // 当前页数
                    $('.last_page').html(last_page);            // 总共页数
                    if(prev_page_url === null) {                // 上一页
                        $('.front-link').hide(); // 没有第一页
                    } else {
                        $('.front-link').attr('href', current+'?cate_id='+cate_id+'&page='+prev_page_url.split("?page=")[1])
                    }
                    if(next_page_url === null) {                // 下一页
                        $('.after-link').hide(); // 没有最后一页
                    } else {
                        $('.after-link').attr('href', current+'?cate_id='+cate_id+'&page='+next_page_url.split("?page=")[1])        
                    }
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 最新、综合、活动跳转
    newsTabPage: function() {
        $('.news .tab-title').on('click', 'a', function(event) {
            $(this).addClass('on').siblings('a').removeClass('on');
            var cate_id = $(this).attr('cate-id');
            window.location.href = window.location.href.split("?")[0]+'?cate_id='+cate_id+'&page=1'
        });
    },
    // 新闻详情
    newsInfo: function() {
        var id = this.getQueryVariable('id');
        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/article/"+id+"",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                $('.news-det .main').addClass('main-show');
                if(json.code == 200) {
                    var newsTitle = json.data.list.article_title;       // 标题
                    var detailsInfo = json.data.list.article_content;   // 内容
                    var origin      = json.data.list.origin;            // 作者
                    var created_at      = json.data.list.created_at;    // 时间
                    $('.news .news-title h3, .current-title').html(newsTitle);
                    $('.news .origin').html(origin);
                    $('.news .created_at').html(created_at);
                    $('.details-info iframe').attr('src', 'news-iframe.html?id='+id);

                    // 翻页功能
                    obj.pageDown(json.data.last, json.data.next, 'id');

                    // 获取当前ifname子页面的高度
                    function iframeAutoFit(iframeObj){ 
                        setTimeout(function(){if(!iframeObj) return;iframeObj.height=(iframeObj.Document?iframeObj.Document.body.scrollHeight+20:iframeObj.contentDocument.body.offsetHeight+20)},200) 
                    }
                    $('#external-frame').load(function(){ //  等iframe加载完毕             
                        iframeAutoFit(document.getElementById('external-frame'));
                        layer.close(index);
                        $("#external-frame").contents().find('.iframe').append(json.data.list.article_content);
                    });
                   
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 翻页功能
    pageDown: function(lastPage, nextPage, cite) {
        var href = window.location.href.split("?")[0] + '?'+cite+'='
        // console.log(href)
        // console.log(lastPage);
        // console.log(nextPage);

        if(lastPage !== '') $('.prev-page').addClass('show').attr('href', href+lastPage.id).attr('title', lastPage.article_title);
        if(nextPage !== '') $('.next-page').addClass('show').attr('href', href+nextPage.id).attr('title', nextPage.article_title);
    },
    // 最新、综合、活动跳转
    worksTabPage: function() {
        $('.colleagues .tab-title').on('click', 'a', function(event) {
            $(this).addClass('on').siblings('a').removeClass('on');
            var cate_id = $(this).attr('cate-id');
            window.location.href = window.location.href.split("?")[0]+'?id='+cate_id
        });
    },
    // 同人专区-作品
    worksAjax: function() {
        var id = this.getQueryVariable('id');
        $('.colleagues .tab-title .a-tab-'+id+'').addClass('on').siblings('a').removeClass('on');
        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/article?cate_id="+id+"",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {

                    $.each(json.data.articleList.data, function(index, values) {
                        var dtHtml = '<li class="grid-item">'+
                            '<a href="drawing-details.html?article='+values.id+'">'+
                                '<img src='+values.thumbnail+'>'+
                                '<div class="pic_info">'+
                                    '<div class="txt_info">'+
                                        '<p class="pic_name" title="'+values.article_title+'">'+values.article_title+'</p>'+
                                        '<div href="javascript:;" class="author_name">'+
                                            '<span>'+values.origin+'</span>'+
                                        '</div>'+
                                    '</div>'+
                                    '<div class="hot_level">'+
                                        '<em></em>'+
                                        '<span>'+values.like_num+'</span>'+
                                    '</div>'+
                                '</div>'+
                           ' </a>'+
                        '</li>';
                        $('#tiles').append(dtHtml);
                    });
                    $('#tiles').imagesLoaded(function() {
                        var options = {
                            itemWidth: 200,
                            autoResize: true,
                            container: $('#tiles'),
                            offset: 15,
                            outerOffset: 0,
                            flexibleWidth: 285 
                        };
                        var handler = $('#tiles li');
                        handler.wookmark(options);
                    });
                    setTimeout(function() {
                        $('.htmleaf-container').addClass('htmleaf-container-show');
                    },500)
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 同人专区-作品详情
    drawingInfo: function() {
        var article = this.getQueryVariable('article');
        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/article/"+article+"",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    var newsTitle   = json.data.list.article_title;     // 标题
                    var detailsInfo = json.data.list.article_content;   // 内容
                    var origin      = json.data.list.origin;            // 作者
                    var created_at  = json.data.list.created_at;        // 时间
                    var like_num    = json.data.list.like_num;          // 点赞
                    var video_link  = json.data.list.video_link;        // 视频

                    // 如果内容是空则为视频-否则默认新闻输出
                    if(detailsInfo == '') {
                        $('.drawing-details .details-info div').append('<video controls="controls" controlslist="nodownload" src="'+video_link+'" width="100%" height="100%" disablepictureinpicture="true" autoplay="autoplay" style="background-color: black;"></video>');
                    }
                    // 新闻输出
                    $('.drawing-details .details-info div').append(detailsInfo);
                    $('.drawing-details .news-title h3, .current-title').html(newsTitle);
                    $('.drawing-details .origin').html(origin);
                    $('.drawing-details .created_at').html(created_at);
                    $('.btnVote').html(like_num);
                    // 翻页功能
                    obj.pageDown(json.data.last, json.data.next, 'article');
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })

        // 点赞
        $('.btnVote').click(function(event) {
            $.ajax({
                url: api +"/web/article/?id="+article+"",
                type: "POST",
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if(json.code == 200) {
                        $('.alert_box').fadeIn();
                        var number = Number($('.btnVote').html())+1;
                        $('.btnVote').html(number)
                    }else {
                        layer.msg("服务器请求失败", {time: 2000});
                    }

                },error: function() {
                    layer.msg("服务器请求失败", {time: 2000});
                },
                complete: function() {
                    layer.close(index);
                }
            })
        });

        // 关闭点赞提示框
        $('.alert_box .alertClose, .alert_box .btnsConfirm').click(function(event) {
            $('.alert_box').fadeOut();
        });
    },
    // 获取同名动画视频
    sameNameAjax: function() {
        var id = this.getQueryVariable('id');
        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/article?cate_id="+id+"&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList1, function(index, values) {
                        // console.log(index)
                        var dtHtml = '<li data="'+values.video_link+'" ><a href="javascript:;">'+Number(index+1)+'</a></li>';
                        $('.outline-01 .select-ul').append(dtHtml);
                    });

                    $.each(json.data.articleList2, function(index, values) {
                        var dtHtml = '<li data="'+values.video_link+'" ><a href="javascript:;">'+Number(index+1)+'</a></li>';
                        $('.outline-02 .select-ul').append(dtHtml);
                    });

                    var number = $('.outline').length;
                    for (var i = number; i >= 1; i--) {
                        var videoUrl = $('.outline-0'+i+' .select-ul').children("li").eq(0).addClass('on').attr('data');
                        $('.outline-0'+i+' .left-video video').attr('src', videoUrl);
                        for (var n = 0; n <= $('.outline-0'+i+' .select-ul li').length; n++) {
                            $('.outline-0'+i+' .right-select span').html('1/'+n+'');
                        };
                    };

                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 视频切换
    videoSwitch: function() {
        $('.select-ul').on('click', 'li', function(event) {        
            var playSrc = $(this).attr('data');
            $(this).addClass('on').siblings().removeClass('on');
            $(this).parents('.right-select').siblings('.left-video').find('video').attr('src', playSrc);
        });
    },
    // 选择不同频道
    channel: function() {
        $('.label').on('click', 'h4', function(event) {
            $(this).addClass('on').siblings().removeClass('on').parent('.label').siblings('.int-wrap').children('.int-bg').eq($(this).index()).addClass('show').siblings().removeClass('show');
            $(this).parents('.introduce').siblings('.animation-content').children('.outline').eq($(this).index()).addClass('show').siblings().removeClass('show');
            tabVideo();
        });

        // 关闭所有视频
        function tabVideo() {
            var auVideo=document.getElementsByTagName("video");
            for(var i=0;i<auVideo.length;i++){
                auVideo[i].pause();         
            }            
        }
    },
    // 获取视频中心视频
    videoCenterAjax: function() {
        var id = this.getQueryVariable('id');
        var limit = 5;      // 精彩视频个数5个
        var index = layer.load(2, {shade: [0.1, '#fff']});
        // 精彩视频5个
        $.ajax({
            url: api +"/web/article?cate_id="+id+"&limit="+limit+"",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList.data, function(index, values) {
                        var dtHtml = '<li data="'+values.video_link+'">'+
                            '<div class="pic"><span><img src="'+values.thumbnail+'"></span></div>'+
                            '<div class="name">'+
                                '<h4>'+values.article_title+'</h4>'+
                                '<p>'+values.article_excerpt+'</p>'+
                            '</div>'+
                        '</li>';
                        $('.video-content .select-ul').append(dtHtml);
                    });
                    // // 第一个视频默认选中，并播放
                    var oneLi = $('.video-center .select-ul').children("li").eq(0);
                    oneLi.addClass('on').attr('data');
                    $('.left-video video').attr('src', oneLi.attr('data'));
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
        
        // 全部视频
        $.ajax({
            url: api +"/web/article?cate_id="+id+"&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList, function(index, values) {
                        var dtHtml = '<li>'+
                            '<a href="video-details.html?id='+values.id+'">'+
                                '<span>'+
                                    '<img src="'+values.thumbnail+'">'+
                                '</span>'+
                            '</a>'+
                            '<p>'+values.article_title+'</p>'+
                        '</li>';
                        $('.more-videos ul').append(dtHtml);
                    });
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 获取详细视频
    videoDetailsAjax: function() {
        var id = this.getQueryVariable('id');
        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/article/"+id+"",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    var newsTitle = json.data.list.article_title;       // 标题
                    var detailsInfo = json.data.list.article_content;   // 内容
                    var origin      = json.data.list.origin;            // 作者
                    var created_at      = json.data.list.created_at;    // 时间
                    var video_link      = json.data.list.video_link;    // 视频
                    $('.video-center .news-title h3, .current-title').html(newsTitle);
                    $('.video-center .details-info').append(detailsInfo);
                    $('.video-center .origin').html(origin);
                    $('.video-center .created_at').html(created_at);
                    $('.video-info video').attr('src', video_link);
                    // 翻页功能
                    obj.pageDown(json.data.last, json.data.next, 'id');
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 获取漫画续集信息
    comicSequelAjax: function() {
        var id = this.getQueryVariable('id');
        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/article?cate_id="+id+"&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    // 渲染漫画列表数量
                    
                    $.each(json.data.articleList, function(index, values) {
                        var dtHtml = '<li><a href="news-details.html?id='+values.id+'">第'+Number(index+1)+'话  '+values.article_title+'</a></li>';
                        $('.cartoon-list ul').append(dtHtml);
                    });
                    $('.cartoon-list ul li:nth-child(1)').html();
                    var info = $('.cartoon-list ul li:nth-child(1) a').html()
                    var href = $('.cartoon-list ul li:nth-child(1) a').attr('href');
                    $('.synopsis .start').attr('href', href).find('span').html(info);
                    $('.cartoon-list span').html('（连载至第'+parseInt(json.data.articleList.length)+'话）');

                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // wiki-贵重物品-筛选
    filtering: function() {
        rarity       = '';  // 品阶
        deviation    = '';  // 五围
        halo_effect  = '';  // 光环技能
        mosaic_skill = '';  // 镶嵌技能
        column       = '';  // 筛选参数1
        order        = '';  // 筛选参数2
        page         = '';  // 第几页
        url          = api + '/web/goods';  // 需要请求的地址

        // 第一次进入时候请求一次
        function wikiDataAjax(url) {
            $('.loading-wrap').fadeIn();
            $.ajax({
                url: url,
                type: "GET",
                data: {
                    rarity: rarity,
                    deviation: deviation,
                    halo_effect: halo_effect,
                    mosaic_skill: mosaic_skill,
                    column: column,
                    order: order,
                    page: page
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if(json.code == 200) {
                        var next_page_url = json.data.data.next_page_url;   // 下一页的数据
                        // 渲染-所有贵重物品
                        // 橙色-1
                        // 蓝色-2
                        // 绿色-3
                        // 紫色-4
                        if(json.data.data.data.length !== 0) {
                            $.each(json.data.data.data, function(index, values) {
                                var addHtml = '<li class="li-color-0'+values.colour+'" titles="'+values.title+'"colour="'+values.colour+'" art="'+values.art+'"  culture="'+values.culture+'" describe="'+values.describe+'" deviation="'+values.deviation+'" faith="'+values.faith+'" get_type="'+values.get_type+'" halo_effect="'+values.halo_effect+'" id="'+values.id+'" mosaic_skill="'+values.mosaic_skill+'" popularity="'+values.popularity+'" quality="'+values.quality+'" rarity="'+values.rarity+'" rarity_order="'+values.rarity_order+'" technology="'+values.technology+'">'+
                                    '<div>'+
                                        '<img src="https://download4.qcplay.com/qcgame-admin/snailValuables/'+values.id+'.png">'+
                                    '</div>'+
                                    '<span>'+values.title+'</span>'+
                                '</li>';
                                $('.goods-list-wrap ul').append(addHtml);
                            });

                            // $.each(json.data.data.data, function(index, values) {
                            //     var addHtml = `<li class="li-color-0${values.colour}" 
                            //     titles="${values.title}"
                            //     colour="${values.colour}" 
                            //     art="${values.art}" 
                            //     culture="${values.culture}" 
                            //     describe="${values.describe}" 
                            //     deviation="${values.deviation}" 
                            //     faith="${values.faith}" 
                            //     get_type="${values.get_type}" 
                            //     halo_effect="${values.halo_effect}" 
                            //     id="${values.id}" 
                            //     mosaic_skill="${values.mosaic_skill}" 
                            //     popularity="${values.popularity}" 
                            //     quality="${values.quality}" 
                            //     rarity="${values.rarity}" 
                            //     rarity_order="${values.rarity_order}" 
                            //     technology="${values.technology}">
                            //         <div>
                            //             <img src="https://download4.qcplay.com/qcgame-admin/snailValuables/${values.id}.png">
                            //         </div>
                            //         <span>${values.title}</span>
                            //     </li>`;
                            //     $('.goods-list-wrap ul').append(addHtml);
                            // });
                            // 如果下页有参数则显示更多按钮
                            next_page_url !== null ? $('.more-wrap').fadeIn().attr('data', next_page_url) : $('.more-wrap').hide();
                            $('.tips-wrap, .loading-wrap').hide();
                        } else {
                            layer.msg("未能找到相应贵重品", {time: 2000});
                            $('.tips-wrap').fadeIn();
                        }
                        $('.loading-wrap').hide();
                    }else {
                        $('.loading-wrap').fadeIn().html('服务器请求失败');
                    }

                },error: function() {
                    $('.loading-wrap').fadeIn().html('服务器请求失败');
                },
                complete: function() {
                    // layer.close(index);
                }
            })
        }
        wikiDataAjax(url);

        // 点击选择按钮
        $('.option-selection-box .selection-box').on('click', 'li', function(event) {
            page = 1;           // 每次点击按钮的时候回到第一页
            var _this = $(this);
            _this.addClass('on').siblings().removeClass('on');            
            getLiData();        // 获取条件数据
            $('.goods-list-wrap ul').find('li').remove(); // 先清除一次再渲染
            wikiDataAjax(url);   // 选择条件数据后ajax请求            
        });


        // 点击更多查看下一页
        $('.more-wrap').click(function(event) {
            page = $(this).attr('data').split("?page=")[1];            
            getLiData();        // 获取条件数据
            wikiDataAjax(url)   // 选择条件数据后ajax请求
        });

        // 获取数据信息
        function getLiData() {
            rarity      = $('.grade').find('.on').attr('data');         // 品阶
            deviation   = $('.dimension').find('.on').attr('data');     // 五围
            halo_effect = $('.halo_effect').find('.on').attr('data');   // 光环技能
            mosaic_skill= $('.mosaic_skill').find('.on').attr('data');  // 镶嵌技能
            column      = $('.grade_array').find('.on').attr('column'); // 筛选参数1
            order       = $('.grade_array').find('.on').attr('order');  // 筛选参数2
        }

        // wiki-贵重物品-筛选-复位清除选项
        $('.reset-btn').click(function(event) {
            $('.goods-list-wrap ul').find('li').remove();
            $('.selection-box ul li:nth-child(1)').addClass('on').siblings().removeClass('on');
            rarity       = '';  // 品阶
            deviation    = '';  // 五围
            halo_effect  = '';  // 光环技能
            mosaic_skill = '';  // 镶嵌技能
            column       = '';  // 筛选参数1
            order        = '';  // 筛选参数2
            page         = '';  // 第几页
            url          = api + '/web/goods';  // 需要请求的地址
            wikiDataAjax(url)
        });
    },
    // 信息切换-关闭弹窗清除数据
    wikiTabInfo: function() {
        $('.info-box .tit').on('click', 'h4', function(event) {
            $(this).addClass('on').siblings().removeClass('on').parent('.tit').siblings('.con').children('div').eq($(this).index()).addClass('show').siblings().removeClass('show');
        }); 
    },
    // 贵重物品弹窗
    wikiPopup: function() {
        // 开启弹窗
        $('.goods-list-wrap').on('click', 'li', function(event) {
            $('.wiki-popup').fadeIn();
            var title       = $(this).attr('titles');       // 标题
            var colour      = $(this).attr('colour');        // 颜色
            var art         = $(this).attr('art');           // 艺术
            var culture     = $(this).attr('culture');       // 文化
            var describe    = $(this).attr('describe');      // 简介
            var deviation   = $(this).attr('deviation');     // 偏向
            var faith       = $(this).attr('faith');         // 信仰
            var get_type    = $(this).attr('get_type');      // 获取途径  
            var halo_effect = $(this).attr('halo_effect');   // 光环技能
            var id          = $(this).attr('id');            // 图片id
            var mosaic_skill= $(this).attr('mosaic_skill');  // 镶嵌技能
            var popularity  = $(this).attr('popularity');    // 人气
            var quality     = $(this).attr('quality');
            var rarity      = $(this).attr('rarity');
            var rarity_order= $(this).attr('rarity_order');
            var technology  = $(this).attr('technology');    // 科技

            if(rarity == '无') rarity = ''                   // 无不输出

            $('.wiki-wrap h1').html(title);                 // 标题
            $('.wiki-wrap .span-pj').html(quality+rarity);  // 颜色
            $('.wiki-wrap .span-px').html(deviation);       // 偏向

            $('.wiki-wrap .pic-img img').attr('src', 'https://download4.qcplay.com/qcgame-admin/snailValuables/'+id+'.png')
            $('.wiki-wrap .pic-img').addClass('pic-img-0'+colour+'');   // 框框颜色

            if(halo_effect == '')  halo_effect = '暂无数据'
            if(mosaic_skill == '') mosaic_skill = '暂无数据'
            if(get_type == '') get_type = '暂无数据'
            if(describe == '') describe = '暂无数据'
            $('.wiki-wrap .p-01').html(halo_effect);         // 光环技能
            $('.wiki-wrap .p-02').html(mosaic_skill);        // 镶嵌技能
            $('.wiki-wrap .p-03').html(get_type);            // 镶嵌技能
            $('.wiki-wrap .p-04').html(describe);            // 简介

            $('.wiki-wrap .data-ul .li-01').html(popularity);// 人气
            $('.wiki-wrap .data-ul .li-02').html(art);       // 艺术
            $('.wiki-wrap .data-ul .li-03').html(faith);     // 信仰
            $('.wiki-wrap .data-ul .li-04').html(culture);   // 文化
            $('.wiki-wrap .data-ul .li-05').html(technology);// 科技

            if(colour == 1) colour ='#f9ab61'
            if(colour == 2) colour ='#9ECBD2'
            if(colour == 3) colour ='#b4cf7b'
            if(colour == 4) colour ='#DCA8C7'
            myChart(popularity,art,faith,culture,technology,colour);
        });

        // 关闭弹窗
        $('.wiki-popup').on('click', '.close-btn', function(event) {
            $('.wiki-popup').fadeOut();
            setTimeout(function() {
                for (var i = 4; i >= 0; i--) $('.wiki-wrap .pic-img').removeClass('pic-img-0'+i+''); // 框框颜色
                $('.wiki-wrap .pic-img img').attr('src', '');
                $('.wiki-wrap h1, .wiki-wrap h2 span').html('');    // 清空标题/颜色
                $('.wiki-wrap .con p').html('');                    // 清空光环技能/镶嵌技能/镶嵌技能/简介
                $('.wiki-wrap .data-ul li').html('');               // 清空/人气/艺术/信仰/文化/科技
                $('.wiki-wrap .tit .h4-01').addClass('on').siblings().removeClass('on');              // tab恢复
                $('.wiki-wrap .con div:nth-child(1)').addClass('show').siblings().removeClass('show');// tab恢复
            },500)
        });

        function myChart(popularity,art,faith,culture,technology,colour) {
            // console.log(colour)
            //指定图表的配置项和数据
            option = {
                //标题    
                title: {
                    text: '贵重物品五维图'
                },
                tooltip: {},
                radar: {
                    center: ['50%', '60%'],
                    startAngle: 90,
                    name: {
                        formatter: '{value}',
                        textStyle: {
                            color: '#937f80',
                            fontSize: '12',
                            lineHeight: 14,
                            padding: [0, 0],
                        },
                        formatter:function(data, indicator){
                            var data0 = data.substring(0, 2)
                            var data1 = data.substring(2)       
                            if (data1 == maxData) indicator.nameTextStyle.color = colour     
                            return data0 + '\n' + data1
                            // return val.split(":").join("\n");
                        },
                    },
                    // 指示器名称和指示器轴的距离。[ default: 5 ]
                    nameGap: 5,
                    // 雷达图圈数设置
                    splitNumber: 2,  
                    // 设置雷达图中间射线的颜色
                    axisLine: {
                        lineStyle: {
                            width : 3,
                            color: '#ae988b',
                        },
                    },
                    splitArea : {
                        show : true ,
                        areaStyle : {
                            color: '#EDDCC4', // 图表背景的颜色
                        },
                    },
                    splitLine : {
                        show : true ,
                        lineStyle : {
                            width : 3,
                            color : '#ac998b', // 设置网格的颜色
                        },
                    },
                    indicator: [
                       { name: '人气'+popularity, max: 120},
                       { name: '科技'+technology, max: 120},
                       { name: '文化'+culture, max: 120},
                       { name: '信仰'+faith, max: 120},
                       { name: '艺术'+art, max: 120},
                    ]
                },
                series: [{
                    type: 'radar',
                     itemStyle: { // 折线拐点标志的样式。
                        normal: { // 普通状态时的样式
                            lineStyle: {
                                width: 3,
                                color: colour,
                            },                              
                        },
                    },
                    data : [
                        {
                            value : [popularity, technology, culture, faith, art],
                            itemStyle: { // 单个拐点标志的样式设置。
                                normal: {
                                    color: 'rgba(60,135,213,.3)',
                                    opacity: 0.2,
                                    // 拐点的描边颜色。[ default: '#000' ]
                                    borderWidth: 1,
                                    // 拐点的描边宽度，默认不描边。[ default: 0 ]
                                }
                            },
                            areaStyle: {
                                normal: {
                                    color: colour // 选择区域颜色
                                }
                            }

                        },
                    ],
                }]
            };
            var maxData = Math.max.apply(this, option.series[0].data[0].value)
            //获取dom容器
            var myChart = echarts.init(document.getElementById('chartmain'));
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        }

    },
    // wiki-贵重物品-搜索
    wikiearch: function() {
        var _this = this;
        $('.search-btn').on('click', function(event) {
            var searchIpu =  $('.search-input').val()
            if(searchIpu == '') {
                layer.msg("请输入您需要搜索的名称", {time: 2000});
                return
            }
            var index = layer.load(2, {shade: [0.1, '#fff']});
            $.ajax({
                url: api + '/web/goods',
                type: "GET",
                data: {
                    title: searchIpu
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if(json.code == 200) {
                        var next_page_url = json.data.data.next_page_url;   // 下一页的数据
                        // 如果下页有参数则显示更多按钮
                        next_page_url !== null ? $('.more-wrap').fadeIn().attr('data', next_page_url) : $('.more-wrap').hide();

                        $('.search-input').val('')  // 搜索完成后搜索栏目清空
                        // 清除信息
                        var gradeSet = resetSet('.grade');
                        var dimensionSet = resetSet('.dimension');
                        var halo_effectSet = resetSet('.halo_effect');
                        var mosaic_skillSet = resetSet('.mosaic_skill');
                        var grade_arraySet = resetSet('.grade_array');
                        function resetSet(li) {
                            $(li).find('li').removeClass('on');
                        }

                        $('.goods-list-wrap ul').find('li').remove();
                        if (json.data.data.data == '') {
                            layer.msg("未能找到相应贵重品", {time: 2000});
                            $('.tips-wrap').fadeIn();
                        } else {
                            $.each(json.data.data.data, function(index, values) {
                                var addHtml = '<li class="li-color-0'+values.colour+'" titles="'+values.title+'"colour="'+values.colour+'" art="'+values.art+'" culture="'+values.culture+'" describe="'+values.describe+'" deviation="'+values.deviation+'" faith="'+values.faith+'" get_type="'+values.get_type+'" halo_effect="'+values.halo_effect+'" id="'+values.id+'" mosaic_skill="'+values.mosaic_skill+'" popularity="'+values.popularity+'" quality="'+values.quality+'" rarity="'+values.rarity+'" rarity_order="'+values.rarity_order+'" technology="'+values.technology+'">'+
                                    '<div>'+
                                        '<img src="https://download4.qcplay.com/qcgame-admin/snailValuables/'+values.id+'.png">'+
                                    '</div>'+
                                    '<span>'+values.title+'</span>'+
                                '</li>';
                                $('.goods-list-wrap ul').append(addHtml);
                            });
                            $('.tips-wrap').hide();
                        }
                    }
                },error: function() {
                    layer.msg("服务器请求失败", {time: 2000});
                },
                complete: function() {
                    layer.close(index);
                }
            })

        });
    },
    // 获取需要筛选的wiki参数
    getWikiData: function() {
        var index = layer.load(2, {shade: [0.1, '#fff']});
        $.ajax({
            url: api +"/web/get_type",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if(json.code == 200) {
                    // 渲染-wiki-光环技能的参数
                    $.each(json.data.data.halo_effect, function(index, values) {
                        var addHtml = '<li data="'+values+'">'+values+'</li>';
                        $('.halo_effect').append(addHtml);
                    });

                    // 渲染-wiki-镶嵌技能的参数
                    $.each(json.data.data.mosaic_skill, function(index, values) {
                        var addHtml = '<li data="'+values+'">'+values+'</li>';
                        $('.mosaic_skill').append(addHtml);
                    });
                    $('.halo_effect, .mosaic_skill').find('li:nth-child(1)').addClass('on').attr('data', '');
                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }

            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // wikiSwiper的轮播
    wikiSwiper: function() {
        var swiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            spaceBetween: 30,
        });
    },
    wikiIndexAjax: function() {
        var index = layer.load(2, {shade: [0.1, '#fff']});
        var _this = this;
        $.ajax({
            url: api +"/web/getdata",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {                
                var ditu = json.data.articleList.ditu;              // 地图探索                
                var hot  = json.data.articleList.hot;               // 热门
                var xinshou= json.data.articleList.xinshou.data;    // 新手
                var xingtai= json.data.articleList.xingtai;         // 形态
                var hot  = json.data.articleList.hot;               // 热门
                var banner  = json.data.articleList.banner;         // 轮播
                var index  = json.data.articleList.index;         // 轮播
                if(json.code == 200) {

                    // // 渲染-地图探索
                    $.each(banner, function(index, values) {
                        var addHtml = '<div class="swiper-slide">'+
                            '<a href="'+values.url+'">'+
                                '<img src="'+values.mobile_background+'">'+
                            '</a>'+
                        '</div>';
                        $('.swiper-container .swiper-wrapper').append(addHtml);
                    });
                    _this.wikiSwiper();
                    
                    // 渲染-地图探索
                    $.each(ditu, function(index, values) {
                        var addHtml = '<li><a href="wiki-details.html?id='+values.id+'"><img src="'+values.thumbnail+'"><span>'+values.article_title+'</span></a></li>';
                        $('.map-exploration ul').prepend(addHtml);
                    });

                    $('.map-exploration li:nth-child(1)').find('span').html('高丽');
                    $('.map-exploration li:nth-child(2)').find('span').html('扶桑');
                    $('.map-exploration li:nth-child(3)').find('span').html('华夏');
                    $('.map-exploration li:nth-child(4)').find('span').html('米国');
                    $('.map-exploration li:nth-child(5)').find('span').html('英伦');

                    // 渲染-热门
                    $.each(hot, function(index, values) {
                        var addHtml = '<li><a href="wiki-details.html?id='+values.id+'">'+values.article_title+'</a></li>';
                        $('.hot-pages ul').append(addHtml);
                    });
                    // 新手指南
                    for (var i = $('.novice-guide li').length-1; i >= 0; i--) {
                        $('.novice-guide .li-0'+i+' a').attr('href','wiki-details.html?id='+xinshou[i].id+'');
                        // console.log(xinshou[i].id)
                    };

                    // 渲染-形态
                    $.each(xingtai, function(index, values) {
                        var addHtml = '<li><a href="wiki-details.html?id='+values.id+'"><img src="'+values.thumbnail+'"><span>'+values.article_title+'</span></a></li>';
                        $('.form-intr ul').prepend(addHtml);
                    });

                    // 先固定写死
                    $('.form-intr li:nth-child(1)').find('span').html('活尸');
                    $('.form-intr li:nth-child(2)').find('span').html('恶魔');
                    $('.form-intr li:nth-child(3)').find('span').html('天使');
                    $('.form-intr li:nth-child(4)').find('span').html('机械');
                    $('.form-intr li:nth-child(5)').find('span').html('异种');
                    $('.form-intr li:nth-child(6)').find('span').html('巨龙');

                    // // 游戏攻略
                    $.each(index, function(index, values) {
                        var addHtml = '<li><a href="wiki-details.html?id='+values.id+'"><span>攻略</span><p>'+values.article_title+'</p></a></li>';
                        $('.game-intr ul').append(addHtml);
                    });
                    // $('.halo_effect, .mosaic_skill').find('li:nth-child(1)').addClass('on').attr('data', '');

                }else {
                    layer.msg("服务器请求失败", {time: 2000});
                }
            },error: function() {
                layer.msg("服务器请求失败", {time: 2000});                
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    share: function() {
        $.ajax({
            url: api + "/wechat/jssdk",
            type: "POST",
            data: {
                uri: window.location.href,
            },
            success: function(json) {

                var ua = navigator.userAgent.toLowerCase();
                if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    wx.config(json.data);
                    wx.ready(function() {
                        wx.updateAppMessageShareData({
                            title: '最强蜗牛', // 分享标题
                            desc: '大家都爱玩的沙雕手游', // 分享描述
                            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: 'https://static.qcplay.com/common/snail_icon.png', // 分享图标
                            success: function() {}
                        })

                        wx.updateTimelineShareData({
                            title: '最强蜗牛-大家都爱玩的沙雕手游', // 分享标题
                            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: 'https://static.qcplay.com/common/snail_icon.png', // 分享图标
                            success: function() {
                                // 设置成功
                            }
                        })
                    });
                    return;
                } else {
                    try {
                        setShareInfo({
                            title: '最强蜗牛', // 分享标题
                            summary: '大家都爱玩的沙雕手游', // 分享内容
                            pic: 'https://static.qcplay.com/common/snail_icon.png', // 分享图片
                            url: window.location.href, // 分享链接
                            // 微信权限验证配置信息，若不在微信传播，可忽略
                            WXconfig: {
                                swapTitleInWX: true, // 是否标题内容互换（仅朋友圈，因朋友圈内只显示标题）
                                appId: json.data.appId, // 公众号的唯一标识
                                timestamp: json.data.timestamp, // 生成签名的时间戳
                                nonceStr: json.data.nonceStr, // 生成签名的随机串
                                signature: json.data.signature // 签名
                            }
                        });
                    } catch (err) {}
                }
            }
        })
    },
    // 阿里云埋点
    countNum: function(data) {
        setTimeout(() => {
            try {
                __bl.sum(data);
            } catch (e) {
                console.log(e)
            }
        }, 500);
    }
}